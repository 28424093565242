'use client'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper'

import './HomeSlider.css'
import FullImageBanner from '../banners/FullImageBanner'
import Title from '../atoms/Title'
import Paragraph from '../atoms/Paragraph'

const HomeSlider = () => {
  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false
      }}
      loop={true}
      pagination={{
        clickable: true
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="homeSlider"
    >
      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/images/home-banners/home-banner-promo-consultoria.png"
          imageMobileUrl="/assets/images/home-banners/home-banner-promo-consultoria-mobile.png"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              Servicios que encaminan al éxito, <span>sin costo alguno</span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Por tiempo limitado brindamos un <strong>servicio adicional sin costo</strong> al adquirir alguno de
              nuestros Servicios de Consultoría
              <br />
              <br />
              <a className="blueBtn" href="/promociones">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/images/home-banners/home-banner-website-itil-4.png"
          imageMobileUrl="/assets/images/home-banners/home-banner-website-itil-4-mobile.png"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              <span>¡Qué no expire tu certificación en</span> ITIL 4!
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Descubre cómo renovarla y especializarte en el marco ITIL<sup>®</sup> con nuestra serie de videos e
              información
              <br />
              <br />
              <a className="blueBtn" href="/cursos-y-certificaciones/itil">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/secciones/landings/servicios/implementacion-iso-iec-27001/banner-seguridad.jpg"
          imageMobileUrl="/assets/secciones/landings/servicios/implementacion-iso-iec-27001/banner-seguridad.jpg"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              Cumple con los requerimientos de la <span>norma ISO/IEC 27001</span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Nuestros consultores y más de 25 años de experiencia te pueden ayudar
              <br />
              <br />
              <a className="blueBtn" href="/landing/servicios-de-consultoria/implementacion-iso-iec-27001">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/images/home-banners/home-banner-curso-donado.png"
          imageMobileUrl="/assets/images/home-banners/home-banner-curso-donado.png"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              Inscríbete sin costo a nuestro curso
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Prevención de Vulnerabilidades Integrando Seguridad en el Desarrollo de Software
              <br />
              <br />
              <a className="blueBtn" href="/landing/prevencion-seguridad-desarrollo-de-software">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/images/home-banners/home-banner-diplomado.jpg"
          imageMobileUrl="/assets/images/home-banners/home-banner-diplomado-mobile.jpg"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              Fortalece tu trayectoria profesional con nuestro <span>Diplomado en Ciberseguridad</span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Desde <strong>fundamentos hasta técnicas avanzadas,</strong> te preparará para enfrentar los desafíos
              actuales en Seguridad Informática
              <br />
              <br />
              <a className="blueBtn" href="/cursos-y-certificaciones/diplomado-en-ciberseguridad">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>
    </Swiper>
  )
}

export default HomeSlider
